<template>
  <BaseDialog width="560px" :title="displayData.title" hideCancel @close="$emit('close')" @confirm="$emit('close')">
    <GrayBlockContainer style="margin-bottom: 32px">
      <FormInfo :gap="12">
        <FormInfoItem
          v-for="item in displayData.contents"
          :key="item.value"
          width="100"
          :title="item.label"
        >
          <div class="whitespace-pre-line" v-html="item.value" />
        </FormInfoItem>
      </FormInfo>
    </GrayBlockContainer>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { get, find, join } from 'lodash'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'

export default defineComponent({
  name: 'PreviewSubscribePlanPeriodicBenefitModal',
  components: {
    BaseDialog,
    GrayBlockContainer,
    FormInfo,
    FormInfoItem,
  },
  props: {
    periodicBenefitTemplateId: String,
    periodicBenefitTemplateList: {
      type: Array,
      default: () => [],
    },
  },
  setup (props) {
    const { formatPeriodPreviewText, getFormatEntitlementList } = usePeriodBenefitTemplate()
    const displayData = computed(() => {
      const data = find(props.periodicBenefitTemplateList, { id: props.periodicBenefitTemplateId })
      const periodConfig = get(data, 'period', {})
      const startType = periodConfig.startType
      const periodType = periodConfig.type
      const instantAward = get(data, 'instantAward')
      let fixedWeekDay = '-'
      const nDayValue = periodConfig.nDayValue || '-'
      const fixedValue = periodConfig.fixedValue || '-'
      const totalAwardCount = get(data, 'totalAwardCount', '-')
      if (periodType === 'weekly' && startType === 'fixed') {
        fixedWeekDay = fixedValue
      }
      return {
        title: get(data, 'name'),
        contents: [
          { label: '權益項目', value: join(getFormatEntitlementList(data), '、') },
          {
            label: '發放週期',
            value: formatPeriodPreviewText({
              startType,
              periodType,
              fixedWeekDay,
              nDayValue,
              fixedValue,
              instantAward,
              totalAwardCount,
            }),
          },
          { label: '發放總次數', value: totalAwardCount },
          { label: '商品描述', value: get(data, 'description') },
        ],
      }
    })
    return { displayData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
