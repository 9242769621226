<template>
  <quillEditor v-model="syncValue" :options="options" />
</template>

<script setup>
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

const props = defineProps({
  value: String,
  optionsOverride: Object,
})
const emit = defineEmits(['update:value'])
const { editorOption } = useEditor()
const syncValue = useVModel(props, 'value', emit)

const options = computed(() => {
  return {
    ...editorOption,
    ...props.optionsOverride,
  }
})
</script>

<style lang="postcss" scoped>
</style>
