import { render, staticRenderFns } from "./BaseTextEditor.vue?vue&type=template&id=c0e261dc&scoped=true"
import script from "./BaseTextEditor.vue?vue&type=script&setup=true&lang=js"
export * from "./BaseTextEditor.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0e261dc",
  null
  
)

export default component.exports